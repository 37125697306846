<template>
  <v-btn
    class="ml-2"
    color="info"
    target="_blank"
    href="https://github.com/acampagnaro/api-tentacle/releases/"
  >
    Download Tentacle
    <v-divider
      class="mx-6"
      vertical
    />
    <v-icon>mdi-cloud-download-outline</v-icon>
  </v-btn>
</template>

<script>
  export default {
    name: 'FileTentacle',
  }
</script>
